import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import LoginForm from '../components/LoginForm'
import SEO from '../components/SEO'

const Login = ({ data }) => {
  return (
    <Layout>
      <SEO title="Login" description="Login description goes here" />
      <Container>
        <PageTitle>Login</PageTitle>
        <LoginForm />
      </Container>
    </Layout>
  )
}

export default Login
