import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import { setUser, isLoggedIn } from '../services/auth'
import { Auth } from 'aws-amplify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import ErrorsList from './ErrorsList'

const LoginForm = () => {
  if (isLoggedIn()) navigate('/account/manage')

  const [errors, setErrors] = useState([])

  return (
    <div className="login-form-container">
      <div className="login-form">
        <div className="title">Login</div>
        <Formik
          initialValues={{ username: '', password: '' }}
          validate={values => {
            const errors = {}
            if (!values.username) {
              errors.username = 'Required'
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)
            ) {
              errors.username = 'Invalid email address'
            }
            if (!values.password) {
              errors.password = 'Required'
            }
            return errors
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              await Auth.signIn(values.username, values.password)
              const user = await Auth.currentAuthenticatedUser()
              const userInfo = {
                ...user.attributes,
                username: user.username,
              }
              setUser(userInfo)
              setErrors([])
              navigate('/account/manage')
            } catch (err) {
              setErrors([err.message])
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <label htmlFor="username">
                Email
                <Field type="email" name="username" />
                <ErrorMessage name="username" component="div" />
              </label>
              <label htmlFor="password">
                Password
                <Field type="password" name="password" />
                <ErrorMessage name="password" component="div" />
              </label>
              <button type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </Form>
          )}
        </Formik>
        <ErrorsList errors={errors} />
        <Link to="/reset-password">Forgot password?</Link>
        <Link to="/register">Sign Up</Link>
      </div>
    </div>
  )
}

export default LoginForm
